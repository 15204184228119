<template>
  <div class="room__message_bar">
    <v-textarea
      class="ml-1 room__message_input"
      dense
      outlined
      rounded
      single-line
      hide-details
      :rows="1"
      :row-height="15"
      :label="$t('label.message')"
      v-model="message"
      :auto-grow="messageInputAutoGrow"
      @input="setDraftMessage"
      @keydown="throttleRoomTyping"
      @keydown.enter.exact.prevent="createMessage">
      <template v-slot:prepend><EmojiMenu @select="injectEmoji" /></template>
    </v-textarea>

    <v-icon
      class="mr-2 ml-2"
      size="30"
      color="primary"
      @click="createMessage"
      :disabled="message.length === 0"
      >mdi-send</v-icon
    >
  </div>
</template>

<script>
import { formatISO } from "date-fns";
import { throttle } from "throttle-debounce";
import { mapActions, mapState } from "vuex";

import messengerService from "@/services/messengerService";

import ChatMessage from "@/store/models/ChatMessage";
import ChatRoom from "@/store/models/ChatRoom";

import EmojiMenu from "@/components/messenger/EmojiMenu";
import user from '@/utils/mixins/user'

export default {
  name: "MessengerMessageBar",
  mixins: [user],
  components: { EmojiMenu },
  data: () => ({
    message: "",
    eventModalShow: false,
    beforeRoomWasCreatedMessagesIds: []
  }),
  computed: {
    ...mapState("auth", { currentUser: "authUser" }),
    ...mapState("messenger", ["activeRoomId", "draftMessage"]),
    messageInputAutoGrow() {
      return this.message.split(/\r\n|\r|\n/).length <= 5;
    },
    activeRoom() {
      return ChatRoom.query().where("uuid", this.$route.query.r).first();
    },
    throttleRoomTyping() {
      return throttle(1000, this.emitRoomTypingEvent);
    },
  },
  mounted() {
    this.message = structuredClone(this.activeRoom.draftMessage);
    const room = ChatRoom.query().where("uuid", this.activeRoom.uuid).first();
    ChatRoom.update({ where: room.id, data: { draftMessage: "" } });
  },
  methods: {
    ...mapActions("messenger", { selectRoom: "selectRoom" }),
    async createMessage() {
      if (!this.message) return false;

      await ChatRoom.update({ where: this.activeRoom.id, data: { draftMessage: "" } })

      const { chatMessages } = await ChatMessage.insert({
        data: {
          userId: this.currentUser.userId,
          roomId: this.activeRoom.uuid,
          message: this.message,
          state: "loading",
          createdAt: formatISO(new Date()),
        },
      });

      this.message = "";

      if (this.activeRoom.createdAt === null && this.activeRoom.state !== "loading") {
        const conversationPartnerId = this.activeRoom
            .participantsIds
            .filter((id) => this.currentUser.userId !== id);

        const room = ChatRoom.query().where("uuid", this.activeRoom.uuid).first();

        await ChatRoom.update({ where: room.id, data: { state: "loading" } });

        try {
          const response = await messengerService
            .openRoom({ id: this.activeRoom.uuid, participantId: conversationPartnerId[0] });

          await ChatRoom.update({ where: room.id, data: { ...(response || {}), state: null } });

          this.sendMessagesPushedBeforeRoomWasCreated();
        } catch (error) {
          await ChatRoom.update({ where: room.id, data: { state: "error" } });

          const loadingMessages = ChatMessage.query().where("state", "loading").get();
          loadingMessages.forEach(message => ChatMessage.update({
            where: item => item.roomId === this.activeRoom.uuid && item.id === message.id,
            data: { state: "error" }
          }));
        }
      }

      this.sendMessageWhenReady(chatMessages[0]);
    },
    sendMessage(message) {
      messengerService
        .sendMessage(message)
        .then((resp) => ChatMessage.update({ data: resp.data }))
        .catch(() => ChatMessage.update({ where: message.id, data: { state: "error" } }));

      ChatRoom.update({
        where: this.activeRoom.id,
        data: { lastMessageId: message.id }
      });
    },
    sendMessageWhenReady(message) {
      if (this.activeRoom.createdAt === null && ["loading", "error"].includes(this.activeRoom.state)) {
        this.beforeRoomWasCreatedMessagesIds.push(message.id);
      } else {
        this.sendMessage(message);
      }
    },
    sendMessagesPushedBeforeRoomWasCreated() {
      while (this.beforeRoomWasCreatedMessagesIds.length > 0) {
        const message = ChatMessage.find(this.beforeRoomWasCreatedMessagesIds.shift());
        this.sendMessage(message);
      }
    },
    setDraftMessage() {
      if (this.activeRoom.createdAt !== null) return;
      ChatRoom.update({ where: this.activeRoom.id, data: { draftMessage: this.message } });
    },
    emitRoomTypingEvent() {
      if (this.activeRoom.createdAt === null || this.isElter) return;

      return this.$echo
        .private("messenger-room." + this.activeRoom.uuid)
        .whisper("typing", this.currentUser);
    },
    injectEmoji(emoji) {
      this.message += emoji.emoji;
    },
  },
};
</script>

<style lang="scss">
.room {
  &__message {
    display: flex;
    margin: 5px 0 !important;

    &_bar {
      position: sticky;
      display: flex;
      align-self: center;
      align-items: center;
      width: 100%;
      padding: 10px;
      max-width: 1224px;
      background-color: #fff;

      .v-input__prepend-outer {
        align-self: center !important;
        margin-top: 4px !important;
      }
    }
  }
}

.emoji {
  cursor: pointer;
  height: 30px;
  width: 30px;
}
</style>
