<template>
  <v-container>
    <v-card>
      <v-toolbar elevation="3">
        <v-btn icon :to="{name:'price-lists'}" class="ml-0" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-card-title class="text-overflow-ellipsis">
          <v-skeleton-loader
              width="150"
              height="12"
              type="text@1"
              v-if="loading"
          />

          <template v-else>
            <span  class="mr-2 text-overflow-ellipsis">{{priceCategory.name}}</span>

            <div class="d-flex align-center subtitle-1">
              <span class="text-caption" :class="{'text-decoration-underline-dotted': priceCategory.studentsCount}" @click="openStudentsDialog">
                {{ priceCategory.studentsCount }} ({{ $t("students") }})
              </span>
            </div>
          </template>
        </v-card-title>

        <v-spacer/>

        <v-btn class="ml-1" v-if="priceCategory.isDefault" text small disabled>{{ $t("label.default") }}</v-btn>

        <PricesListsSettings
          :licenses="licenses"
          :price-category="priceCategory"
          @openPriceCatFormDialog="editPriceCategory"
          @deleted="onDeletePriceCategory"
          @licensesAdded="onLicensesAdded"
          @updateIsDefault="onUpdateIsDefault"
        />
      </v-toolbar>

      <PriceListLoader v-if="loading"/>

      <v-card-text v-else>
            <v-alert
                v-if="!priceCategory.isActive"
                type="warning"
            >
              {{ $t("messages.price_category_is_inactive") }}
            </v-alert>
        <v-row>
          <v-col>
            <v-tabs dense show-arrows center-active v-model="selectedLicense" @change="onChangeLicense">
              <v-tab v-for="license in licenses" :key="license.id" :href="`#${license.id}`">
                <v-badge
                    class="license-badge"
                    :color="license.isCompleted ? 'success' : 'warning'"
                    :icon="license.isCompleted ? 'mdi-check': 'mdi-exclamation'"
                    :value="true"
                    offset-y="15"
                    offset-x="-5"
                >
                  {{ license.name }}
                </v-badge>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row :key="temporarySelectedLicense">
          <v-col>
            <span class="title font-weight-medium text--secondary">{{ $t('label.one_time_product') }}</span>

            <div class="mt-5">
              <ProductPrice
                  ref="productPrice"
                  type="one-time-product"
                  v-for="oneTimeProduct in oneTimeProducts"
                  :key="oneTimeProduct.id"
                  :id="oneTimeProduct.id"
                  :title="$t(`one_time_product.${oneTimeProduct.key}`)"
                  :price="oneTimeProductPrices[oneTimeProduct.id]"
                  @updateActiveStatus="onUpdateActiveStatus"
              />
            </div>
          </v-col>

          <v-col>
            <span class="title font-weight-medium text--secondary">{{ $t("label.lesson_types") }}</span>

            <div class="mt-5">
              <ProductPrice
                  ref="productPrice"
                  type="lesson-type"
                  v-for="lesson in lessons"
                  :key="lesson.id"
                  :id="lesson.id"
                  :title="$tc(lesson.name, 1)"
                  :price="lessonsPrices[lesson.id]"
                  @updateActiveStatus="onUpdateActiveStatus"
              />
            </div>
          </v-col>

          <v-col>
            <span class="title font-weight-medium text--secondary">{{ $t("exams_title") }}</span>

            <div class="mt-5">
              <ProductPrice
                  ref="productPrice"
                  type="lesson-type"
                  v-for="exam in exams"
                  :key="exam.id"
                  :id="exam.id"
                  :title="$tc(exam.name, 1)"
                  :price="examsPrices[exam.id]"
                  @updateActiveStatus="onUpdateActiveStatus"
              />
            </div>
          </v-col>
        </v-row>

        <v-btn v-if="!loading && !isSelectedLicenseCompleted" small color="primary" @click="saveLessonTypesPrices">
          {{ $t("btn.save") }}
        </v-btn>

        <v-divider class="my-4"/>

        <v-row class="d-flex justify-space-between">
          <v-col cols="12" md="3">
            <span class="title font-weight-medium text--secondary">{{$t("label.learning_materials")}}</span>
          </v-col>

          <v-col class="d-flex justify-end" cols="12" md="9">
            <v-btn
                text
                small
                color="primary"
                :disabled="loading"
                @click="createLearningMaterial"
            >
              {{ $t("btn.new_learning_material") }}
            </v-btn>
          </v-col>
        </v-row>

        <div class="mt-5">
          <LearningMaterials :learningMaterials="learningMaterials" @edit="editLearningMaterial"/>
        </div>

        <PricesConfirmationDialog ref="priceCategoryConfirmationDialog" @confirm="onConfirm"/>
        <PricesCategoryFormDialog ref="pricesCategoryFormDialog" @update="onUpdatePriceCategory"/>
        <LearningMaterialDialog ref="learningMaterialFormDialog"/>
        <StudentsListDialog ref="studentsDialog"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { showConfirm } from '@/helpers/dialogs'
import keyBy from '@/helpers/keyBy'

import priceCategoriesService from '@/services/priceCategoriesService'
import PriceListLoader from '@/components/skeleton-loaders/PriceListLoader'
import LessonTypeModel from '@/store/models/LessonTypeModel'
import ProductPrice from '@/components/products/ProductPrice.vue'
import LearningMaterials from '@/components/products/LearningMaterials'
import LearningMaterialDialog from '@/components/products/LearningMaterialDialog'
import PricesListsSettings from '@/components/PricesListsSettings'
import LearningMaterialModel from '@/store/models/LearningMaterialModel'
import learningMaterialsService from '@/services/learningMaterialsService'
import PricesCategoryFormDialog from '@/components/products/PricesCategoryFormDialog'
import StudentsListDialog from '@/components/student/StudentsListDialog'
import PricesConfirmationDialog from '@/components/products/PricesConfirmationDialog'
import productsService from '@/services/productsService'
import OneTimeProductModel from '@/store/models/OneTimeProductModel'

export default {
  name: 'PriceList',
  components: {
    PricesConfirmationDialog,
    StudentsListDialog,
    PricesCategoryFormDialog,
    PricesListsSettings,
    LearningMaterialDialog,
    LearningMaterials,
    ProductPrice,
    PriceListLoader
  },
  data() {
    return {
      loadingPriceCategories: false,
      loadingOneTimeProducts: false,
      priceCategory: {
        id: null,
        name: '',
        isDefault: false,
        isActive: false,
        studentsCount: 0
      },
      licenses: [],
      selectedLicense: null,
      temporarySelectedLicense: null
    }
  },
  computed: {
    loading() {
      return this.loadingPriceCategories || this.loadingOneTimeProducts
    },
    selectedLicenseObject() {
      return this.licenses.find(license => license.id === Number(this.selectedLicense))
    },
    isSelectedLicenseCompleted() {
      return this.selectedLicenseObject?.isCompleted
    },
    oneTimeProducts () {
      return OneTimeProductModel.all()
    },
    lessonTypes() {
      return LessonTypeModel.query().get().filter((item) => item.payable !== "no_cost");
    },
    lessonTypesBySelectedLicense() {
      return this.lessonTypes
          .filter(lessonType => {
            return lessonType.forLicenses === null ||
                Array.isArray(lessonType.forLicenses) && lessonType.forLicenses.includes(parseInt(this.selectedLicense))
          })
    },
    lessons() {
      return this.lessonTypesBySelectedLicense.filter((item) => item.group !== "exam");
    },
    exams() {
      return this.lessonTypesBySelectedLicense.filter((item) => item.group === "exam");
    },
    selectedLicensePrices() {
      const license = this.licenses.find(license => license.id === Number(this.selectedLicense))

      return license ? license.prices : []
    },
    oneTimeProductPrices() {
      const prices = this.selectedLicensePrices.filter(price => price.productType === 'one-time-product')

      return keyBy(prices, 'productId')
    },
    lessonTypesPrices() {
      return this.selectedLicensePrices.filter(item => item.productType === 'lesson-type')
    },
    lessonsPrices() {
      const prices = this.lessonTypesPrices
          .filter(price => this.lessons.some(item => item.id === Number(price.productId)))

      return keyBy(prices, 'productId')
    },
    examsPrices() {
      const prices = this.lessonTypesPrices
          .filter(price => this.exams.some(item => item.id === Number(price.productId)))

      return keyBy(prices, 'productId')
    },
    learningMaterials() {
      return LearningMaterialModel.query()
          .where("priceCategoryId", this.priceCategory.id)
          .where("licenseId", Number(this.selectedLicense))
          .get();
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (await this.checkIfThereAreFilledPrices()){
      next(false)
    } else {
      next()
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted () {
    this.loadingPriceCategories = true
    priceCategoriesService
      .get(this.$route.params.id)
      .then(response => {
        const data = response.data || {}
        this.priceCategory = {
          id: data.id,
          name: data.name,
          isDefault: data.isDefault,
          isActive: data.isActive,
          studentsCount: data.studentsCount,
          studentsIds: data.studentsIds
        }
        this.licenses = data.licenses || []
      })
      .catch((error) => {
        const response = error.response || {};
        const { status, data } = response;

        if (status && [404, 403].includes(status)) {
          this.$router.push({ name: "price-lists" });
          this.$snackbar.show({ message: data ? data.message : "", color: "error", timeout: 5000 });
        }
      }).finally(() => {
        this.loadingPriceCategories = false
      })

    this.loadingOneTimeProducts = true
    productsService
      .oneTimeProducts()
      .then(response => OneTimeProductModel.create({data: response.data || []}))
        .finally(() => {
          this.loadingOneTimeProducts = false
        })

    learningMaterialsService
      .load({ priceCategoryId: this.$route.params.id })
      .then((response) => LearningMaterialModel.create({ data: response.data }))
  },
  methods: {
    editPriceCategory() {
      this.$refs.pricesCategoryFormDialog.open(this.priceCategory);
    },
    onCategoryCreated(priceCategory) {
      this.form.priceCategoryId = priceCategory.id;
      this.setPriceCategoryIdInQuery();
    },
    onDeletePriceCategory() {
      this.$router.push({name: 'price-lists'})
    },
    onUpdatePriceCategory(priceCategory) {
      this.priceCategory.name = priceCategory.name
    },
    openStudentsDialog() {
      if (this.priceCategory.studentsIds.length === 0) return
      this.$refs.studentsDialog.open(this.priceCategory.studentsIds);
    },
    onLicensesAdded(licenses) {
      this.licenses = licenses
    },
    onUpdateIsDefault(status) {
      this.priceCategory.isDefault = status
    },
    createLearningMaterial() {
      this.$refs.learningMaterialFormDialog.openForCreate(this.priceCategory.id, this.selectedLicense)
    },
    editLearningMaterial(id) {
      this.$refs.learningMaterialFormDialog.openForEdit(id)
    },
    getProductsFormData() {
      if (!this.$refs.productPrice) return []

      return this.$refs.productPrice
        .map(productPrice => productPrice.getFormData())
        .filter(formData => formData.createdAt === null)
        .map(formData => ({
          id: formData.id,
          type: formData.type,
          price: formData.price
        }));
    },
    async onChangeLicense() {
      if (await this.checkIfThereAreFilledPrices()) {
        this.$nextTick(() => {
          this.selectedLicense = this.temporarySelectedLicense
        })
        return
      }
      this.temporarySelectedLicense = this.selectedLicense
    },
    saveLessonTypesPrices() {
      this.$refs.priceCategoryConfirmationDialog.open(
          this.priceCategory.id,
          this.selectedLicense,
          this.getProductsFormData()
      );
    },
    onUpdateActiveStatus(data) {
      const selectedLicenseIndex = this.licenses.findIndex(license => license.id === Number(this.selectedLicense))
      const selectedLicense = this.licenses[selectedLicenseIndex] || {}
      const selectedLicensePrices = selectedLicense.prices || []
      const priceIndex = selectedLicensePrices.findIndex(price => price.id === data.priceId)
      const price = selectedLicensePrices[priceIndex]

      if (price) {
        price.active = data.active
        this.$set(this.licenses[selectedLicenseIndex].prices, priceIndex, price)
      }
    },
    async checkIfThereAreFilledPrices() {
      const nonZeroPrices = this.getProductsFormData().filter((formData) => parseFloat(formData.price) !== 0)

      return nonZeroPrices.length > 0 &&
          !await showConfirm(this.$t('messages.unsaved_changes'), this.$t('messages.do_you_really_want_to_leave'))
    },
    async beforeWindowUnload(e) {
      if (await this.checkIfThereAreFilledPrices()) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    onConfirm(data) {
      const selectedLicenseIndex = this.licenses.findIndex(license => license.id === Number(data.licenseId))

      if (selectedLicenseIndex !== -1) {
        const dataPrices = data.prices || []
        const licensePrices = this.licenses[selectedLicenseIndex].prices || []

        this.$set(this.licenses[selectedLicenseIndex], 'prices', [...licensePrices, ...dataPrices])
        this.$set(this.licenses[selectedLicenseIndex], 'isCompleted', data.isPriceListCompleted)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.license-badge {
  .v-badge__badge {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;
  }
  .v-badge__badge {
    padding: 2px !important;
  }
}
</style>
