import AppointmentModel from "@/store/models/AppointmentModel";
import events from '@/const/appointmentEvents'
import laravelEcho from "@/plugins/laravel-echo";
import TeacherModel from '@/store/models/TeacherModel'
import {isWithinInterval, parseISO, addWeeks, isPast, addMinutes} from 'date-fns'

export default class CalendarWebsocketsHandlers {
    constructor(user, isSchoolAdministrator = false, isTeacher = false, isStudent = false) {
        this.isSchoolAdministrator = isSchoolAdministrator;
        this.isTeacher = isTeacher;
        this.isStudent = isStudent;
        this.currentUser = user;
        this.allEvents = Object.values(events);
    }

    startListening(events = []) {
        if (! events.length) events = this.allEvents;

        events.forEach(event => {
            if (this.allEvents.includes(event)) {
                const handler = `handle${event}`
                if (handler in this) {
                    laravelEcho
                        .private("school-channel." + this.currentUser.schoolId)
                        .listen(event, appointment => {
                            this[handler](appointment);
                        })
                } else {
                    console.error(`Unable to handle ${event} event, method ${handler} does not exist!`);
                }
            }
        })
    }

    _schoolAndTeacherBehavior(appointment) {
        if (this.isSchoolAdministrator) {
            this._insert(appointment);
        }

        if (this.isTeacher) {
            if (appointment.teacherId === this.currentUser.id) {
              this._insert(appointment);

              const overlapped = AppointmentModel.query()
                .where(item => item.id !== appointment.id)
                .where('teacherId', appointment.teacherId)
                .where('type', ['PLACEHOLDER', 'OFFTIME'])
                .where((item) => {
                  const appStart = parseISO(appointment.start)
                  const appEnd = addMinutes(appStart, appointment.duration)
                  const start = parseISO(item.start)
                  const end = addMinutes(start, item.duration)
                  return isWithinInterval(appStart, { start, end })
                    || isWithinInterval(appEnd, { start, end })
                    || (appStart <= start && appEnd >= end)
                })
                .first()
              if (overlapped) {
                this._delete(overlapped);
              }
            } else {
                this._delete(appointment);
            }
        }
    }

  handleAppointmentCreatedOrUpdated(appointment) {
    this._schoolAndTeacherBehavior(appointment);
    if (!this.isStudent) return;

    const {
      start, type, specialType, duration, teacherId, studentsIds, roomSize, studentsCount, group, isRequested,
      allowedLicenses, hasMultipleRequestsAllowed
    } = appointment;

    const pluckStudentsIds = ['THEORY', 'PLACEHOLDER'].includes(type)
      ? studentsIds.map(item => item.id)
      : studentsIds;

    const theoryRoomsLeft = !roomSize || roomSize > studentsCount;
    const eventWithStudentTeachers = this.currentUser.teachersIds.includes(teacherId);
    const studentRelatedEvent = pluckStudentsIds?.includes(this.currentUser.id);
    const isInPast = isPast(addMinutes(parseISO(start), duration));
    const { onlyPlaceholdersBooking, showStudentsAllTeachersEvents } = TeacherModel.find(teacherId) || {}
    const showAsBlockedTime = teacherId && (!onlyPlaceholdersBooking || showStudentsAllTeachersEvents)

    const isWithinAllowedLicenses = ['PLACEHOLDER', 'THEORY'].includes(type)
      && this.currentUser.licensesIds.some(studentLicense => allowedLicenses.includes(studentLicense));

    switch (type) {
      case 'OFFTIME': {
        if (isInPast || !showAsBlockedTime) return this._delete(appointment);
        return this._insert(appointment);
      }
      case 'PRACTICE': {
        if (!studentRelatedEvent && (isInPast || !showAsBlockedTime)) return this._delete(appointment);
        return this._insert(appointment);
      }


      case 'PLACEHOLDER': {
        const isFull = !hasMultipleRequestsAllowed && isRequested && !studentRelatedEvent;
        const { bookingLimitMax } = TeacherModel.find(teacherId);
        const isWithinPeriod = isWithinInterval(parseISO(start), {
          start: new Date(),
          end: addWeeks(new Date(), bookingLimitMax),
        });
        const licenseIsWithinPeriod = showAsBlockedTime && (allowedLicenses.length === 0 || isWithinAllowedLicenses)
        const isWithinTeacherPeriod = bookingLimitMax === 0 || isWithinPeriod;

        if (!licenseIsWithinPeriod || !isWithinTeacherPeriod || !eventWithStudentTeachers || isFull
          || (isInPast && !studentRelatedEvent)) {
          return this._delete(appointment);
        }

        if ((eventWithStudentTeachers && !isRequested) || studentRelatedEvent) {
          return this._insert({ ...appointment, isRequested: studentRelatedEvent });
        }
        return this._insert(appointment);
      }

      case 'SPECIAL': {
        if (((isInPast || !showAsBlockedTime) && !studentRelatedEvent)
          || (['STANDARD', 'OFFICE'].includes(specialType) && !eventWithStudentTeachers)
          || (specialType === 'PRETEST' && !studentRelatedEvent)) {

          return this._delete(appointment)
        }

        return this._insert(appointment);
      }

      case 'THEORY': {
        const isTheoryLesson = group === 'lesson';
        const isTheoryExam = group === 'exam';

        if (isTheoryExam && studentRelatedEvent) {
          return this._insert({ ...appointment, isSubscribed: studentRelatedEvent });
        }

        if (isTheoryLesson && isWithinAllowedLicenses && (studentRelatedEvent || !isInPast)) {
          if (!theoryRoomsLeft && !studentRelatedEvent && !showAsBlockedTime) return;
          return this._insert({ ...appointment, isSubscribed: isTheoryLesson && studentRelatedEvent });
        }

        return this._delete(appointment);
      }

      case 'SIMULATOR': {
        if ((!studentRelatedEvent && studentsIds.length) || (isInPast && !studentRelatedEvent)) {
          return this._delete(appointment);
        }
        if (studentRelatedEvent || !studentsIds.length) {
          return this._insert({ ...appointment, isSubscribed: studentRelatedEvent });
        }
        return this._insert(appointment);
      }

      default:
        break;
    }
  }

    handlePlaceholderOrPracticeConfirmed(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        if (this.isStudent) {
            const eventWithStudentTeachers = this.currentUser.teachersIds.includes(appointment.teacherId);
            const studentRelatedEvent = appointment.studentsIds?.includes(this.currentUser.id);

            if (eventWithStudentTeachers || studentRelatedEvent) {
                this._insert(appointment);
            }
        }
    }

    handleAppointmentCreated(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment);
    }

    handleAppointmentUpdated(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment);
    }

    handleAppointmentDeleted(appointment) {
        this._delete(appointment)
    }

    handleAppointmentRestored(appointment) {
        this.handleAppointmentCreatedOrUpdated(appointment)
    }

    handlePlaceholderRequested(appointment) {
        this._schoolAndTeacherBehavior(appointment)

        if (this.isStudent) {
            const studentsIds = appointment.studentsIds.map(item => item.id)
            const isForStudent = studentsIds.includes(this.currentUser.id)
            if (isForStudent) {
                this._insert(appointment)
            } else if (! appointment.hasMultipleRequestsAllowed) {
                this._delete(appointment);
            } else {
                this._insert({...appointment, isRequested: isForStudent})
            }
        }
    }

    handlePlaceholderConfirmed(appointment) {
        this.handlePlaceholderOrPracticeConfirmed(appointment)
    }

    handlePlaceholderDeclined(appointment) {
        const studentsIds = appointment.studentsIds.map(item => item.id)

        this._schoolAndTeacherBehavior({ ...appointment, isRequested: studentsIds.length > 0 });

        if (this.isStudent) {
            const isForStudent = studentsIds.includes(this.currentUser.id)
            const eventWithStudentTeachers = this.currentUser.teachersIds.includes(appointment.teacherId);

            if (eventWithStudentTeachers || isForStudent) {
                this._insert({ ...appointment, isRequested: isForStudent })
            }
        }
    }

    handlePracticeRequestConfirmed(appointment) {
        this.handlePlaceholderOrPracticeConfirmed(appointment)
    }

    handlePracticeRequestDeclined(appointment) {
        this._delete(appointment);
    }

    handleTheoryParticipantAdded(appointment) {
      this._schoolAndTeacherBehavior(appointment)
      if (!this.isStudent) return

      const { onlyPlaceholdersBooking, showStudentsAllTeachersEvents } = TeacherModel.find(appointment.teacherId)
      const showAsBlockedTime = !onlyPlaceholdersBooking || showStudentsAllTeachersEvents
      const studentsIds = appointment.studentsIds.map(item => item.id)
      const isForStudent = studentsIds.includes(this.currentUser.id);
      const hasRoomLeft = !appointment.roomSize || appointment.roomSize > appointment.studentsCount

      if (isForStudent || hasRoomLeft || showAsBlockedTime) {
          this._insert({ ...appointment, isSubscribed: isForStudent })
      } else {
          this._delete(appointment)
      }
    }

    handleTheoryParticipantRemoved(appointment){
        this._insert(appointment);
    }

    handleSimulatorSubscribed(appointment){
        const isForStudent = this.isStudent && appointment.studentsIds.includes(this.currentUser.id);

        if (this.isSchoolAdministrator || isForStudent) {
            this._insert(appointment);
        } else {
            this._delete(appointment);
        }
    }

    handleSimulatorUnsubscribed(appointment){
      const {start, duration} = appointment;
      const isInPast = isPast(addMinutes(parseISO(start), duration));
      if (this.isStudent && isInPast) {
        return this._delete(appointment);
      }
      return this._insert(appointment);
    }

    _insert(appointment) {
        AppointmentModel.insert({ data: appointment })
    }

    _delete(appointment) {
        AppointmentModel.delete(appointment.id)
    }
}
