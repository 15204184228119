import api from "@/utils/api";

export default {
  load() {
    return new Promise((resolve, reject) => {
      api
        .get("price-categories")
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  get: (priceCategoryId) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/price-categories/${priceCategoryId}`)
        .then((resp) => resolve(resp))
        .catch(reject);
    });
  },
  store(params) {
    return new Promise((resolve, reject) => {
      api
        .post("price-categories", params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  setDefault(priceCategoryId, isDefault) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/price-categories/${priceCategoryId}/default`, { isDefault })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  toggleActiveStatus(priceCategoryId) {
    return new Promise((resolve, reject) => {
      api
        .patch(`/price-categories/${priceCategoryId}/active-status`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  getLicenses(priceCategoryId, params = null) {
    return new Promise((resolve, reject) => {
      api
        .get(`/price-categories/${priceCategoryId}/licenses`, { params })
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  addLicenses(priceCategoryId, params) {
    return new Promise((resolve, reject) => {
      api
        .post(`/price-categories/${priceCategoryId}/licenses`, params)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
  removeScheduledPriceLists(priceCategoryId) {
    return new Promise((resolve, reject) => {
      api
        .delete(`/price-categories/${priceCategoryId}/remove-scheduled-price-lists`)
        .then((resp) => {
          resolve(resp);
        })
        .catch(reject);
    });
  },
}
