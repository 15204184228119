<template>
  <div>
    <v-row v-if="isSchoolAdministrator">
      <v-col class="pt-1">
        <v-btn-toggle
            class="special-tabs"
            v-model="specialType"
            :group="false"
            :borderless="false"
            mandatory
        >
          <v-btn value="STANDARD" :disabled="currentAppointment.id && specialType !== 'STANDARD'" text small>
            {{ $t("label.standard") }}
          </v-btn>
          <v-btn value="PRETEST" :disabled="currentAppointment.id && specialType !== 'PRETEST' || holiday" text small>
            {{ $t("label.pretest") }}
          </v-btn>
          <v-btn value="OFFICE" :disabled="currentAppointment.id && specialType !== 'OFFICE'" text small>
            {{ $t("label.office") }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <template v-if="specialType === 'STANDARD'">
      <v-row v-if="isSchoolAdministrator">
        <v-col>
          <TeachersField
              @emitTeachersSelect="updateTeacherField"
              :disabled="!!createdPlaceholderApp"
              :validation="true"
              :filteredTeachers="teachers"
              :teacherProp="currentAppointment.teacherId"
          />
        </v-col>
      </v-row>
      <v-row v-if="!holiday">
        <v-col>
          <StudentsField
            @emitStudentChange="(val) => updateFields({ studentsIds: val })"
            :disabled="teacherIsNotSelected"
            :validation="false"
            :multiple="true"
            :clearable="true"
            :decorated="false"
            :studentsProp="currentAppointment.studentsIds"
            :studentsList="teacherStudents" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SpecialNameField
            @emitSpecialNameChange="(val) => updateFields({ specialName: val })"
            :disabled="teacherIsNotSelected"
            :validation="true"
            :specialNameProp="currentAppointment.specialName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NotesField
            @emitNotesChange="(val) => updateFields({ notes: val })"
            :disabled="teacherIsNotSelected"
            :validation="false"
            :notesProp="currentAppointment.notes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <WorkTimeStatusSwitch
            @emitSwitchStatus="(val) => updateFields({ isWorkTime: val })"
            :label="$t('label.teacher_work_time')"
            :disabled="teacherIsNotSelected || !!currentAppointment.hasSignature"
            :statusProp="currentAppointment.isWorkTime" />
        </v-col>
        <v-col>
          <SignatureField v-if="showSignature" />
        </v-col>
      </v-row>
    </template>

    <template v-if="isSchoolAdministrator && specialType === 'PRETEST'">
      <v-row>
        <v-col>
          <StudentsField
            @emitStudentChange="(val) => updateFields({ studentsIds: val })"
            :disabled="false"
            :validation="true"
            :decorated="false"
            :multiple="true"
            :clearable="true"
            :studentsProp="currentAppointment.studentsIds"
            :studentsList="schoolStudents" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <OfficesField
            @emitOfficeChange="(val) => updateFields({ officeId: val })"
            :validation="true"
            :disabled="false"
            :officeIdProp="currentAppointment.officeId"
            :officesList="schoolOffices" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SpecialNameField
            @emitSpecialNameChange="(val) => updateFields({ specialName: val })"
            :disabled="false"
            :validation="false"
            :specialNameProp="pretestSpecialName"
            :itemsList="titles" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NotesField
            @emitNotesChange="(val) => updateFields({ notes: val })"
            :disabled="false"
            :validation="false"
            :notesProp="currentAppointment.notes" />
        </v-col>
      </v-row>
    </template>

    <template v-if="isSchoolAdministrator && specialType === 'OFFICE'">
      <v-row>
        <v-col>
          <SpecialNameField
            @emitSpecialNameChange="(val) => updateFields({ specialName: val })"
            :disabled="false"
            :validation="true"
            :specialNameProp="currentAppointment.specialName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NotesField
            @emitNotesChange="(val) => updateFields({ notes: val })"
            :disabled="false"
            :validation="false"
            :notesProp="currentAppointment.notes" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TeachersField from "@/components/calendar/appointmentModal/fields/TeachersField";
import StudentsField from "@/components/calendar/appointmentModal/fields/StudentsField";
import SpecialNameField from "@/components/calendar/appointmentModal/fields/SpecialNameField";
import NotesField from "@/components/calendar/appointmentModal/fields/NotesField";
import WorkTimeStatusSwitch from "@/components/calendar/appointmentModal/fields/StatusSwitch";
import OfficesField from "@/components/calendar/appointmentModal/fields/OfficesField";
import SignatureField from "@/components/calendar/appointmentModal/fields/SignatureField";

import StudentModel from "@/store/models/StudentModel";
import OfficeModel from "@/store/models/OfficeModel";
import user from '@/utils/mixins/user'
import DateRange from '@/helpers/dateRange'
import { parseISO } from 'date-fns'

export default {
  name: "SpecialForm",
  mixins: [user],
  components: {
    TeachersField,
    StudentsField,
    SpecialNameField,
    NotesField,
    WorkTimeStatusSwitch,
    OfficesField,
    SignatureField,
  },
  data: () => ({
    titles: ["Vortest", "Anmeldung"],
    title: "",
    isWorkTime: true,
    initStudentsIds: [],
  }),
  props: {
    teachers: {
      type: Array,
      required: true,
    },
    createdPlaceholderApp: {
      type: Object,
    },
  },
  mounted () {
    if (this.createdPlaceholderApp) this.updateFields({ specialType: 'STANDARD' })
    this.initStudentsIds = [ ...this.currentAppointment.studentsIds ]
  },
  computed: {
    ...mapGetters("school", { schoolSettings: "school" }),
    ...mapGetters("appointments", { currentAppointment: "currentAppointmentGetter", }),
    holiday() {
      const regions = this.schoolSettings.regions?.split(", ");
      return new DateRange().isHoliday(parseISO(this.currentAppointment.start), regions);
    },
    showSignature () {
      return this.currentAppointment.id &&
          this.currentAppointment.specialType === 'STANDARD' &&
          this.currentAppointment.isWorkTime
    },
    teacherIsNotSelected() {
      return !this.currentAppointment.teacherId;
    },
    schoolOffices() {
      return OfficeModel.all() || [];
    },
    // teacherStudents() {
    //   return StudentModel.query()
    //       .where('educationStatus', 'learning')
    //       .where((student) => !student.isDeleted && (student.active || this.initStudentsIds.includes(student.id)))
    //       .where("teachersIds", (teachersIds) => teachersIds.includes(this.currentAppointment.teacherId))
    //       .get()
    // },
    teacherStudents() {
      return StudentModel.query()
          .where((student) => {
            return (student.educationStatus === 'learning' && student.active)
                || this.initStudentsIds.includes(student.id)
          })
          .where("teachersIds", (teachersIds) => teachersIds.includes(this.currentAppointment.teacherId))
          .get()
    },
    schoolStudents () {
      return StudentModel.query()
          .where((student) => {
            return (student.educationStatus === 'learning' && student.active)
                || this.initStudentsIds.includes(student.id)
          })
          .get() || []
    },
    pretestSpecialName() {
      return this.currentAppointment.specialName ? this.currentAppointment.specialName : "Vortest";
    },
    specialType: {
      set(value) {
        this.updateFields({
          specialType: value,
          teacherId: null,
          studentsIds: [],
          isWorkTime: false,
          officeId: null,
          specialName: "",
          notes: "",
        });
      },
      get() {
        return this.currentAppointment.specialType;
      },
    },
  },
  methods: {
    updateTeacherField(value) {
      this.updateFields({ teacherId: value, studentsIds: [] })
    },
    updateFields(value) {
      this.$store.commit(`appointments/UPDATE_APPOINTMENT_FIELD`, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.special-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .v-btn--active {
  }
}
</style>
