export const obj = {
  OFFTIME: {
    create: ['start', 'duration', 'notes', 'type', 'teacherId', 'copyForNrOfWeeks'],
    update: ['id', 'start', 'duration', 'notes', 'type', 'teacherId', 'copyForNrOfWeeks'],
  },
  PLACEHOLDER: {
    create: ['start', 'duration', 'notes', 'meetingPlace', 'type', 'teacherId', 'allowedLicenses', 'notificationLicenses', 'copyForNrOfWeeks', 'exceptedDays', 'hasMultipleRequestsAllowed'],
    update: ['id', 'start', 'duration', 'notes', 'meetingPlace', 'type', 'teacherId', 'allowedLicenses', 'notificationLicenses', 'copyForNrOfWeeks', 'exceptedDays', 'hasMultipleRequestsAllowed'],
    request: ['licenseId', 'lessonTypeId', 'meetingPlace'],
    confirm: ['studentsIds', 'teacherId', 'licenseId', 'lessonTypeId', 'start', 'duration', 'meetingPlace', 'notes', 'primaryVehicleId', 'secondaryVehicleId'],
  },
  PRACTICE: {
    create: ['start', 'duration', 'notes', 'isManualLesson', 'meetingPlace', 'type', 'teacherId', 'studentsIds', 'licenseId', 'lessonTypeId', 'primaryVehicleId', 'secondaryVehicleId'],
    update: ['id', 'start', 'duration', 'notes', 'isManualLesson', 'meetingPlace', 'type', 'teacherId', 'studentsIds', 'licenseId', 'lessonTypeId', 'primaryVehicleId', 'secondaryVehicleId'],
    confirm: ['start', 'duration', 'notes', 'isManualLesson', 'meetingPlace', 'type', 'teacherId', 'studentsIds', 'licenseId', 'lessonTypeId', 'primaryVehicleId', 'secondaryVehicleId'],
  },
  SPECIAL: {
    create: ['start', 'duration', 'notes', 'meetingPlace', 'officeId', 'type', 'teacherId', 'specialType', 'specialName', 'studentsIds', 'isWorkTime'],
    update: ['id', 'start', 'duration', 'notes', 'meetingPlace', 'officeId', 'type', 'teacherId', 'specialType', 'specialName', 'studentsIds', 'isWorkTime'],
  },
  SIMULATOR: {
    create: ['start', 'duration', 'notes', 'meetingPlace', 'officeId', 'type', 'teacherId', 'simulatorName', 'licenseId', 'lessonTypeId', 'studentsIds', 'copyForNrOfWeeks', 'exceptedDays'],
    update: ['id', 'start', 'duration', 'notes', 'meetingPlace', 'officeId', 'type', 'teacherId', 'simulatorName', 'licenseId', 'lessonTypeId', 'studentsIds', 'copyForNrOfWeeks', 'exceptedDays'],
  },
  THEORY: {
    create: ['start', 'duration', 'notes', 'officeId', 'type', 'teacherId', 'lessonTypeId', 'roomSize', 'theoryTopicId', 'licenseId', 'studentsIds', 'allowedLicenses'],
    update: ['id', 'start', 'duration', 'notes', 'officeId', 'type', 'teacherId', 'lessonTypeId', 'roomSize', 'theoryTopicId', 'licenseId', 'allowedLicenses'],
  },
};
