<template>
  <div
    class="chat__sidebar"
    v-if="
      (!$route.query.r && $vuetify.breakpoint.xsOnly) ||
      !$vuetify.breakpoint.xsOnly
    ">
    <v-card
      tile
      outlined
      class="sidebar__panel text-center"
      :class="{ 'sidebar__panel-mini': isSideBarMini }"
      v-click-outside="onClickOutsidePanel"
    >
      <div
        class="d-flex px-2 pt-2 pb-1 text-center align-center"
        :class="isSideBarMini ? 'justify-center' : 'justify-space-between'"
      >
        <span v-if="!isSideBarMini" class="title text-left">{{ isOpenedSearchList ? $t("label.contacts") : $t("label.chats") }}</span>

        <v-btn
          v-if="isOpenedSearchList"
          class="pa-0 mt-2 mr-1"
          icon
        >
          <v-icon @click="closeParticipantsList">mdi-arrow-left</v-icon>
        </v-btn>

        <ShowsAs
            v-else
            :type="$vuetify.breakpoint.mobile ? 'dialog' : 'menu'"
            :options="{
              offsetX: true,
              offsetY: true,
              right: true,
              zIndex: 10,
              minWidth: 300
            }"
            ref="filtersMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              v-if="!isElter"
              color="primary"
              overlap
              :value="Object.keys(filters).length > 0"
              :content="Object.keys(filters).length"
              dot
            >
              <v-btn
                class="pa-0 mt-2 mr-1"
                color="primary"
                icon
                text
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>

          <v-card :tile="$vuetify.breakpoint.mobile">
            <v-card-title>{{ $t("label.filters") }}</v-card-title>

            <v-card-text>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-checkbox class="mt-0" v-model="unseenMessages" :label="$t('label.unseen_messages')"/>
                </v-col>
              </v-row>

              <v-row dense v-if="isSchoolAdministrator">
                <v-col cols="12">
                  <v-select
                      v-model="userType"
                      :items="userTypeOptions"
                      :label="$t('label.filter_by_participant_type')"
                      dense
                      hide-details
                      outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn color="red" text @click="clearFilters">{{ $t("btn.clear") }}</v-btn>
              <v-spacer />
              <v-btn color="primary" text @click="applyFilters">{{ $t("btn.ok") }}</v-btn>
            </v-card-actions>
          </v-card>
        </ShowsAs>
      </div>

      <MessengerSidebarSearch
        v-if="!isStudent"
        :isSideBarMini="isSideBarMini"
        :isOpenedSearchList="isOpenedSearchList"
        @search="searchParticipants"
        @sidebarToggled="toggleSideBar"
        @openParticipantsList="onOpenParticipantsList"
      />

      <div
        class="d-flex flex-column"
        :class="{ 'align-center': isSideBarMini }"
        v-if="loadingRooms || loadingParticipants || loadingFilters">
        <v-skeleton-loader
          :class="{ 'mb-2': isSideBarMini }"
          :type="isSideBarMini ? 'avatar' : 'list-item-avatar-two-line'"
          v-for="repeatNr in 3"
          :key="repeatNr" />
      </div>

      <div v-if="!(loadingParticipants || loadingFilters)" class="participants fill-height" ref="participants" @scroll="onScrollParticipants">
        <MessengerParticipants
          v-if="isOpenedSearchList && !loadingParticipants"
          :participants="filteredParticipants"
          :loading-more="loadingMoreParticipants"
          :is-searching="isSearchingParticipants"
          :search-text="searchParticipantsText"
          @select="onSelectParticipant"
        />

        <template v-if="!loadingRooms && !isOpenedSearchList">
          <MessengerRoomsGroup v-if="isSchoolAdministrator"/>

          <template v-else>
            <MessengerSchool v-if="showSchoolRoom" />
            <MessengerRoomsGroup type="teacher" :title="$t('label.teachers')" />
            <MessengerRoomsGroup type="student" :title="$t('label.students')" />
          </template>

          <v-btn
            v-if="roomsPagination.currentPage < roomsPagination.lastPage"
            v-intersect="loadMoreRoomsOnIntersection"
            :disabled="loadingLoadMore"
            :loading="loadingLoadMore"
            class="mb-3"
            color="primary"
            small
            text
            @click="loadMoreRooms"
          >
            {{ $t("btn.load_more") }}
          </v-btn>

          <empty-box v-if="rooms.length === 0 && !showSchoolRoom" />
        </template>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ClickOutside } from "vuetify/lib/directives";

import ChatRoom from "@/store/models/ChatRoom";
import ChatParticipant from "@/store/models/ChatParticipant";
import messengerService from "@/services/messengerService";
import MessengerSidebarSearch from "@/components/messenger/MessengerSidebarSearch";
import MessengerSchool from "@/components/messenger/MessengerSchool";
import MessengerParticipants from "@/components/messenger/MessengerParticipants";
import MessengerRoomsGroup from "@/components/messenger/MessengerRoomsGroup";
import messenger from "@/utils/mixins/messenger";
import user from "@/utils/mixins/user";
import ChatMessage from '@/store/models/ChatMessage'
import ShowsAs from '@/components/ShowsAs.vue'

export default {
  name: "MessengerSidebar",
  mixins: [messenger, user],
  directives: { ClickOutside },
  components: {
    ShowsAs,
    MessengerRoomsGroup,
    MessengerParticipants,
    MessengerSchool,
    MessengerSidebarSearch,
  },
  data: () => ({
    isOpenedSearchList: false,
    loadingMoreParticipants: false,
    isSearchingParticipants: false,
    isOpenParticipantsList: false,
    loadingLoadMore: false,
    loadingFilters: false,
    participantsPagination: {
      currentPage: 0,
      lastPage: 0
    },
    unseenMessages: false,
    userType: "all",
    filters: {}
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapState("messenger", {
      participantsOnlineId: "onlineIds",
      isSideBarMini: "isSideBarMini"
    }),
    ...mapGetters("messenger", { schoolRoom: "schoolRoom" }),
    showSchoolRoom() {
      return !this.filters.unseenMessages ||
        (this.filters.unseenMessages && this.schoolRoom.unSeenMessagesCount > 0);
    },
    userTypeOptions() {
      return [{
        value: "all",
        text: this.$t("label.all")
      }, {
        value: "teacher",
        text: this.$t("list.teachers")
      }, {
        value: "student",
        text: this.$t('list.students')
      }]
    },
    activeRoom() {
      return ChatRoom.query().find(this.$route.query.r);
    },
    rooms() {
      return ChatRoom.query()
        .where((room) => room.createdAt !== null)
        .orderBy("id", "desc")
        .all();
    },
    roomsPagination() {
      return this.$store.state.entities.chatRooms.pagination;
    },
    searchParticipantsText() {
      return this.$store.state.entities.chatParticipants.searchText;
    },
    loadingParticipants() {
      return this.$store.state.entities.chatParticipants.loading;
    },
    loadingRooms() {
      return this.$store.state.entities.chatRooms.loading;
    },
    participants() {
      return ChatParticipant.query()
        .where("type", (value) => value !== this.currentUser.type)
        .orderBy("id", "asc")
        .all();
    },
    filteredParticipants() {
      const participants = this.participants.filter(
        (item) => item.type !== "school-administrator"
      );

      if (!this.searchParticipantsText) return participants;

      return participants.filter((item) => {
        return item.fullName
          .toLowerCase()
          .includes(this.searchParticipantsText.toLowerCase());
      });
    },
  },
  methods: {
    onScrollParticipants() {
      const sideBar = this.$refs.participants;
      const scrollPosition = sideBar.offsetHeight + sideBar.scrollTop;
      const scrollHeight = sideBar.scrollHeight;

      if (this.isOpenedSearchList) {
        if (
          this.participantsPagination.currentPage <
            this.participantsPagination.lastPage &&
          !this.loadingMoreParticipants
        ) {
          if (scrollPosition === scrollHeight) {
            this.loadingMoreParticipants = true;
            this.fetchParticipants({
              searchText: this.searchParticipantsText,
              page: this.participantsPagination.currentPage + 1,
            }).finally(() => {
              this.loadingMoreParticipants = false;

              this.$nextTick(() => {
                sideBar.scrollTop = sideBar.scrollHeight - scrollHeight;
              });
            });
          }
        }
      }
    },
    searchParticipants() {
      if (this.filteredParticipants.length > 7) return false;

      this.isSearchingParticipants = true;
      this.fetchParticipants({ searchText: this.searchParticipantsText })
        .finally(() => {
          this.isSearchingParticipants = false;
        });
    },
    fetchParticipants(params = {}) {
      params.pagination = true;
      params.perPage = 50;
      return messengerService.loadParticipants(params)
        .then((resp) => {
          if (resp.data.pagination) {
            this.participantsPagination = resp.data.pagination
          }
          ChatParticipant.insert({ data: resp.data.data || [] })
        })
    },
    toggleSideBar() {
      this.$store.dispatch("messenger/toggleSideBar");
    },
    onOpenParticipantsList() {
      this.isOpenedSearchList = true;

      ChatParticipant.commit((state) => {
        state.loading = true;
      });
      this.fetchParticipants().finally(() => {
        ChatParticipant.commit((state) => {
          state.loading = false;
        });
      });
    },
    onSelectParticipant(participant) {
      this.closeParticipantsList();

      this.openDialogRoom(participant);
    },
    onClickOutsidePanel() {
      this.closeParticipantsList();
    },
    closeParticipantsList() {
      this.isOpenedSearchList = false;

      ChatParticipant.commit((state) => {
        state.searchText = "";
      });
    },
    fetchRooms(params = {}, callback = () => {}) {
      return messengerService
        .loadRooms({
          ...this.filters,
          pagination: true,
          perPage: this.roomsPagination.perPage,
          page: params.page ?? this.roomsPagination.page
        })
        .then((resp) => {
          const lastMessages = [];
          const responseData = resp.data || {};
          const rooms = responseData.data || [];

          this.roomsPagination.set(responseData.pagination || {})

          rooms.forEach((room) => lastMessages.push(room.lastMessage));

          callback(rooms, lastMessages)
        })
    },
    loadMoreRooms() {
      if (this.roomsPagination.currentPage >= this.roomsPagination.lastPage) return

      this.roomsPagination.setPage(this.roomsPagination.page + 1)

      this.loadingLoadMore = true
      this.fetchRooms({}, (rooms, lastMessages) => {
        // ChatRoom.insert({ data: rooms });
        ChatMessage.insert({ data: lastMessages.length ? lastMessages : [] });
      })
          .finally(() => {
            this.loadingLoadMore = false
          });
    },
    loadMoreRoomsOnIntersection(entries) {
      if (! entries[0]?.isIntersecting) return
      this.loadMoreRooms()
    },
    handleFilters() {
      this.loadingFilters = true
      this.fetchRooms( { page: 1 }, (rooms, lastMessages) => {
        const activeRoom = {...this.activeRoom}
        const activeRoomMessages = ChatMessage.query().where('roomId', activeRoom.id).get()

        ChatRoom.create({ data: [...rooms, activeRoom] });

        if(lastMessages.length > 0) {
          ChatMessage.create({data: [...lastMessages, ...activeRoomMessages]});
        }

        if (!this.unseenMessages) {
          this.initRoomsForStudentsTeachers()
        }
      })
        .finally(() => {
          this.loadingFilters = false
        });
    },
    clearFilters() {
      this.$refs.filtersMenu.close();

      this.unseenMessages = false;
      this.userType = "all";

      this.filters = {};

      this.handleFilters();
    },
    applyFilters() {
      this.$refs.filtersMenu.close();

      this.applyFilter("unseenMessages", this.unseenMessages);
      this.applyFilter("userType", this.userType !== "all");

      this.handleFilters();
    },
    applyFilter(filterName, apply = false) {
      if (apply) {
        this.$set(this.filters, filterName, this[filterName]);
      } else {
        delete this.filters[filterName];
      }
    }
  },
};
</script>

<style scoped lang="scss">
.chat__sidebar {
  display: flex;
  flex-direction: row;
  position: relative;

  .sidebar__panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    position: relative;
    border-left: none !important;
    border-right: none !important;

    &-mini {
      width: 75px;
    }

    .participants {
      overflow-y: auto;
    }
  }
}

.participant {
  &__header {
    max-width: 100%;
  }

  &__avatar {
    overflow: unset;
  }
}
</style>
