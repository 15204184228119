<template>
<v-card>
  <VehicleToolBar title="vehicleFueling" activeTab="fueling">
    <v-btn color="primary" text @click="$refs.vehicleFuelingDialog.open($route.params.id)">
      <v-icon>mdi-plus</v-icon>
      {{ $t("btn.add") }}
    </v-btn>
  </VehicleToolBar>

  <v-card-text>
    <v-skeleton-loader v-if="loading" type="table" />
    <template v-else>
      <empty-box v-if="! refuelings.length" />
      <v-simple-table v-else dense>
        <thead>
        <tr>
            <th class="text-left">{{ $t("label.date") }}</th>
            <th class="text-left">{{ $t("label.liters") }}</th>
            <th class="text-left">{{ $t("label.cost_per_liter") }}</th>
            <th class="text-left">{{ $t("label.total_cost") }}</th>
            <th class="text-left">{{ $t("label.mileage") }}</th>
            <th class="text-left">{{ $t("label.recorded_by") }}</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="refuel in refuelings"
          :key="refuel.id"
          @mouseenter="hovered = refuel.id"
          @mouseleave="hovered = null"
        >
          <td>{{ refuel.date }}</td>
          <td>
            {{ refuel.fuelAmount}} l.
          </td>
          <td>{{ refuel.fuelPrice }} c.</td>
          <td>{{ refuel.cost | currency }}</td>
          <td>
            <template v-if="refuel.mileage">
              {{ refuel.mileage }} Km
            </template>
            <template v-else>&mdash;</template>
          </td>
          <td class="d-flex justify-space-between align-center">
            {{ refuel.author }}
            <v-btn text x-small color="red" @click="deleteRefueling(refuel.id)">
              {{ $t('btn.delete') }}
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </template>
    <VehicleRefuelDialog ref="vehicleFuelingDialog" />
  </v-card-text>
</v-card>
</template>

<script>
import {EventBus} from "@/EventBus";
import vehiclesService from "@/services/vehiclesService";
import VehicleRefuelDialog from "@/components/vehicle/VehicleRefuelDialog.vue";
import { showConfirm } from "@/helpers/dialogs";
import VehicleToolBar from '@/components/vehicle/VehicleToolBar.vue'

export default {
  name: 'VehicleFueling',
  components: {
    VehicleToolBar,
    VehicleRefuelDialog},
  data() {
    return {
      loading: true,
      refuelings: [],
      hovered: null,
    };
  },
  created() {
    EventBus.$on('refueling-created', this.handleRefuelingCreated);
  },
  destroyed() {
    EventBus.$off('refueling-created', this.handleRefuelingCreated);
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      vehiclesService.loadRefuelings(this.$route.params.id)
        .then(response => {
          this.refuelings = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteRefueling(refuelingId) {
      showConfirm(
        this.$t("btn.confirm"),
        this.$t("messages.are_your_sure_delete"),
        () => {
          vehiclesService.deleteRefueling(this.$route.params.id, refuelingId)
            .then(() => {
              this.load();
              EventBus.$emit("refueling-deleted");
            });
        });
    },
    handleRefuelingCreated (e) {
      this.refuelings.unshift(e)
    }
  }
}
</script>

<style scoped>

</style>
