<template>
<v-container :fluid="$vuetify.breakpoint.lgAndDown">
  <v-card :loading="loading">
    <v-toolbar elevation="3">
      <v-card-title>{{ $t('route.priceLists') }}</v-card-title>
      <v-spacer />
      <v-btn text color="primary" @click="createPriceCategory">{{ $t("btn.new_price_category")  }}</v-btn>
    </v-toolbar>

      <v-skeleton-loader v-if="loading" class="mx-auto" type="table-row@3" />

      <template v-else>
        <div class="d-flex justify-end pt-3 pr-5">
          <v-checkbox
              v-model="showInactive"
              :label="$t('label.price_lists_show_hidden')"
              class="mr-5"
              solo
              dense
              hide-details
              @change="filterByStatus"
          />
        </div>
        <v-simple-table v-if="categories.length" dense class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{$t('list.name')}}</th>
                <th class="text-center" style="width: 50px;">{{$t('list.licenses')}}</th>
                <th class="text-center" style="width: 50px;">{{$t('list.students')}}</th>
                <th class="text-center" style="width: 120px;">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon left small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{$t('messages.default_price_category_status_info')}}</span>
                  </v-tooltip>
                  {{$t('list.default')}}
                </th>
                <th style="width: 170px"></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="item in categories" :key="item.id"  :class="`table-row ${!item.isActive  ? 'inactive' : ''}`">
              <td>{{ item.name }}</td>
              <td class="text-center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        :color="item.hasIncompleteLicenses ? 'warning' : 'success'"
                        dark
                        label
                        small
                        link
                        v-bind="attrs"
                        v-on="on"
                        style="min-width: 55px;"
                    >
                      <v-icon left small>
                        {{ item.hasIncompleteLicenses ? 'mdi-alert' : 'mdi-check' }}
                      </v-icon>
                      {{ item.licensesIds.length }}
                    </v-chip>
                  </template>
                  <span>
                    {{ item.licensesNames.join(', ') }}
                  </span>
                </v-tooltip>
              </td>
              <td class="text-center">{{ item.studentsCount }}</td>
              <td class="text-center">
                <v-hover v-if="item.isDefault" v-slot="{ hover }">
                  <v-icon :color="hover ? 'error' : 'success'" @click="setDefaultStatus(item, false)">
                    {{ hover ? 'mdi-close' : 'mdi-check' }}
                  </v-icon>
                </v-hover>

                <v-tooltip v-else top :disabled="item.isActive">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        v-bind="attrs" v-on="on"
                        @click="setDefaultStatus(item, true)"
                        class="hidden-until-row-hovered"
                        :class="item.isActive || 'is-disabled'"
                        link
                        label
                        x-small
                    >
                      <v-icon x-small>mdi-check</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ $t('messages.inactive_price_category_cannot_be_default') }}</span>
                </v-tooltip>
              </td>
              <td class="d-flex justify-center align-center">
                <v-chip
                    class="hidden-until-row-hovered mx-1"
                    link
                    label
                    x-small
                    :to="{ name: 'price-list', params: { id: item.id }}"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-chip>

                <v-tooltip top :disabled="!item.isDefault">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                        @click="toggleVisibility(item)"
                        v-bind="attrs" v-on="on"
                        :class="!item.isDefault || 'is-disabled'"
                        class="hidden-until-row-hovered mx-1"
                        link
                        label
                        x-small
                    >
                      <v-icon x-small>{{ item.isActive ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                    </v-chip>
                  </template>
                  <span>{{$t('messages.default_price_category_cannot_be_hidden')}}</span>
                </v-tooltip>

                <v-chip
                    v-if="item.studentsCount === 0"
                    class="hidden-until-row-hovered mx-1"
                    link
                    label
                    x-small
                    @click="deleteItem(item.id)"
                >
                  <v-icon x-small>mdi-close</v-icon>
                </v-chip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <empty-box v-else />
      </template>
  </v-card>

  <PricesCategoryFormDialog ref="pricesCategoryFormDialog" @created="onPriceCategoryCreated"/>
</v-container>
</template>

<script>
import { showConfirm } from '@/helpers/dialogs'

import LicenseModel from '@/store/models/LicenseModel'
import productsService from '@/services/productsService'
import ProductCategoryModel from '@/store/models/ProductCategoryModel'
import PricesCategoryFormDialog from '@/components/products/PricesCategoryFormDialog'
import priceCategoriesService from '@/services/priceCategoriesService'

export default {
  name: 'Index',
  components: { PricesCategoryFormDialog },
  data() {
    return {
      loading: true,
      showInactive: true,
    }
  },
  computed: {
    licenses() {
      return LicenseModel.all();
    },
    categories() {
      const query = ProductCategoryModel.query().orderBy('isActive', 'desc')
      if (!this.showInactive) query.where((item) => item.isActive)

      return query.all().map(priceCategory => {
        const licensesIds = priceCategory.licensesIds.map(licenseId => licenseId.id)

        return {
          ...priceCategory,
          licensesNames: LicenseModel.query().whereIdIn(licensesIds).get().map(license => license.name),
          hasIncompleteLicenses: priceCategory.licensesIds.some(licenseId => licenseId.completed === false)
        }
      })
    },
  },
  mounted () {
    this.showInactive = JSON.parse(sessionStorage.getItem('showInactive'))
    this.loading = true;
    priceCategoriesService
      .load()
      .then((response) => {
        ProductCategoryModel.create({ data: response.data || [] })
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    deleteItem(itemId) {
      showConfirm(
          this.$t('btn.confirm'),
          this.$t("messages.are_your_sure_delete"),
          () => {
            productsService
              .deletePriceCategory(itemId)
              .then(() => {
                ProductCategoryModel.delete(itemId)
              }).catch(error => {
                const response = error.response || {};
                const responseData = response.data || {};

                if (responseData.message) {
                  this.$snackbar.show({ message: responseData.message, color: "error", });
                }
              });
          }
      );
    },
    setDefaultStatus(item, status) {
      if (!item.isActive) return
      priceCategoriesService
          .setDefault(item.id, status)
          .then(() => {
            ProductCategoryModel.update({
              where: (selectedPriceCategory) => selectedPriceCategory.id !== item.id,
              data: { isDefault: 0 },
            }).then(() =>
                ProductCategoryModel.update({ where: item.id, data: { isDefault: status }, })
            );
          })
          .catch((error) => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
    },

    toggleVisibility(item) {
      if (item.isDefault) return
      if (item.isScheduled && item.isActive) {
        showConfirm(
            this.$t('btn.confirm'),
            this.$t("messages.are_your_sure_hide_scheduled_price_category"),
            () => {
              priceCategoriesService
                  .removeScheduledPriceLists(item.id)
                  .then(() => {
                    ProductCategoryModel.update({ where: item.id, data: { isScheduled: false }})
                    this.setVisibility(item)
                  })
                  .finally(() => {

                  })
            }
        );
      } else {
        this.setVisibility(item)
      }
    },

    setVisibility(item) {
      priceCategoriesService
          .toggleActiveStatus(item.id)
          .then(() => {
            ProductCategoryModel.update({ where: item.id, data: { isActive: !item.isActive }})
          })
          .catch((error) => {
            const response = error.response || {};
            const responseData = response.data || {};

            if (responseData.message) {
              this.$snackbar.show({ message: responseData.message, color: "error", });
            }
          })
    },

    createPriceCategory() {
      this.$refs.pricesCategoryFormDialog.open();
    },
    onPriceCategoryCreated(priceCategory) {
      this.$router.push({name: 'price-list', params: {id: priceCategory.id}})
    },

    filterByStatus(val) {
      this.showInactive = val
      sessionStorage.setItem('showInactive', val)
    },
  }
}

const navigationEntries = performance.getEntriesByType("navigation");
if (navigationEntries.length > 0) {
  const navigationType = navigationEntries[0].type;

  if (navigationType === 'reload') {
    sessionStorage.setItem('showInactive', 'true')
  }
}

</script>

<style scoped lang="scss">
  .is-disabled{
    opacity: 0.4;
  }
  .inactive {
    background: #ffebee !important;
  }
</style>
