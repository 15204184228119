<template>
  <v-card class="chat__room room" outlined tile>
    <EmptyBox
      :width="100"
      :height="100"
      v-if="!rooms.length && currentUser.type === 'school-administrator'"
      class="messages__notification mx-auto"
      image="assets/svg/conversation.svg"
    >
      <div class="d-flex flex-column mt-2">
        {{ $t("label.you_have_no_conversations") }}
      </div>
    </EmptyBox>

    <EmptyBox
      :width="100"
      :height="100"
      v-else-if="!activeRoom"
      image="assets/svg/no-conversation.svg">
      <span>{{ $t("label.select_a_chat") }}</span>
    </EmptyBox>

    <template v-if="activeRoom">
      <MessengerRoomHeader />

      <div class="room__content">
        <div
          class="room__messages"
          ref="roomContent"
          @scroll="onScrollMessages"
        >

        <v-progress-circular
            class="room__loader align-self-center"
            v-if="loadingMessages"
            color="primary"
            indeterminate
            size="50" />

          <template v-if="!loadingMessages">
            <EmptyBox
              :width="100"
              :height="100"
              v-if="activeRoom && !messages.length"
              image="assets/svg/messages.svg">
              <span>{{ $t("label.no_messages_yet") }}</span>
            </EmptyBox>

            <LoadMoreMessagesBtn v-if="showLoadMoreMessageButton && isLoadMoreAvailable" :loading="loadMoreMessages" />

            <div
              class="messages__date"
              v-for="(messagesByUser, date) in sortedMessages"
              :key="date"
            >
              <div class="text-center mb-5 mt-5 text-subtitle-2">
                <v-chip small>{{ date }}</v-chip>
              </div>

              <MessagesByUser
                v-for="(user, index) in messagesByUser"
                :key="index"
                :user="user"
              />
            </div>
          </template>
        </div>

        <ScrollToBottomBtn v-show="isScrollUp" @click="scrollToBottom" />
        <MessengerMessageBar v-if="!isElter" />
      </div>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { compareAsc, format, parseISO } from "date-fns";

import ChatRoom from "@/store/models/ChatRoom";
import ChatParticipant from "@/store/models/ChatParticipant";
import ChatMessage from "@/store/models/ChatMessage";
import MessengerMessageBar from "@/components/messenger/MessengerMessageBar";
import MessengerRoomHeader from "@/components/messenger/MessengerRoomHeader";
import EmptyBox from "@/components/EmptyBox";
import messenger from "@/utils/mixins/messenger";

import messengerService from "@/services/messengerService";
import user from '@/utils/mixins/user'

import LoadMoreMessagesBtn from "@/components/messenger/LoadMoreMessagesBtn";
import ScrollToBottomBtn from "@/components/messenger/ScrollToBottomBtn";

import MessagesByUser from "@/components/messenger/MessagesByUser";
import * as authMutationTypes from "@/store/mutation-types/auth";
import LocalizationManager from '@/helpers/LocalizationManager'
import axios from 'axios'


export default {
  name: "MessengerRoom",
  mixins: [user, messenger],
  components: {
    MessagesByUser,
    ScrollToBottomBtn,
    LoadMoreMessagesBtn,
    MessengerMessageBar,
    MessengerRoomHeader,
    EmptyBox,
  },
  data: () => ({
    menuFrom: false,
    from: null,
    fromFieldFormat: null,
    loadingMessages: false,
    loadMoreMessages: false,
    showLoadMoreMessageButton: false,
    showSearch: false,
    dialog: false,
    fab: false,
    isScrollUp: false,
    isTyping: false,
    messagesPagination: {
      currentPage: 1,
      lastPage: 1,
    },
    initialMessagesPagination: {},
  }),
  computed: {
    ...mapGetters("school", ["school"]),
    rooms() {
      return ChatRoom.all();
    },
    activeRoom() {
      return ChatRoom.query().where("uuid", this.$route.query.r).first();
    },
    conversationPartner() {
      return ChatRoom
            .query()
            .with("conversationPartner")
            .find(this.activeRoom?.id)
            ?.conversationPartner || {}
    },
    messages() {
      const messages = this.activeRoom
        ? ChatMessage.query().where("roomId", this.activeRoom.uuid).get()
        : [];

      return (
        messages.sort((first, second) =>
          compareAsc(parseISO(first.createdAt), parseISO(second.createdAt))
        ) || []
      );
    },
    sortedMessages() {
      const messagesGroups = {};
      const messagesByDate = this.messagesByDate(this.messages);

      Object.keys(messagesByDate).map((date) => {
        const messagesByUser = this.messagesByUser(messagesByDate[date]);

        if (!(date in messagesGroups)) messagesGroups[date] = {};

        Object.keys(messagesByUser).map((userId) => {
          if (!(userId in messagesGroups[date])) {
            messagesGroups[date][userId] = [];
          }
          messagesGroups[date][userId] = messagesByUser[userId];
        });
      });

      return messagesGroups;
    },
    isLoadMoreAvailable() {
      return (
        this.messagesPagination.currentPage < this.messagesPagination.lastPage
      );
    },
  },
  watch: {
    messages: function () {
      if (!this.isScrollUp) setTimeout(() => this.scrollToBottom(), 0);
    }
  },
  created() {
    this.messagesPagination = this.initialMessagesPagination;

    if (this.activeRoom && this.activeRoom.createdAt !== null) {
      this.initRoom(this.activeRoom);
    }

    setTimeout(() => this.scrollToBottom(), 0);
  },
  methods: {
    initRoom(room) {
      this.isScrollUp = false;
      this.loadParticipants(room);
      this.loadMessages(room.uuid);
    },
    onScrollMessages(e) {
      this.loadMoreMessagesOnScroll(e.target);
      this.checkScroll(e);
    },
    loadMessages(roomId) {
      this.loadingMessages = true;
      messengerService
        .loadMessages({ roomId: roomId })
        .then((resp) => {
          const pagination = resp.data.pagination || {
            currentPage: 1,
            lastPage: 1,
          };

          this.messagesPagination = {
            currentPage: pagination.currentPage,
            lastPage: pagination.lastPage,
          };

          ChatMessage.insert({ data: resp.data.data || [] });

          const room = ChatRoom.query().where("uuid", roomId).first();

          if (room) {
            ChatRoom.update({
              where: room.id,
              data: { unSeenMessagesCount: 0 },
            }).then(() => {
              const userUnseenMessagesCount = this.currentUser.unSeenMessagesCount - room.unSeenMessagesCount;

              this.$store.commit(
                `auth/${authMutationTypes.SET_UNSEEN_MESSAGES_COUNT}`,
                userUnseenMessagesCount >= 0 ? userUnseenMessagesCount : 0
              );

              this.scrollToBottom();
              setTimeout(() => (this.showLoadMoreMessageButton = true), 200);
            });
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            localStorage.removeItem("chat-active-dialog");
          }
        })
        .finally(() => {
          this.loadingMessages = false;
        });
    },
    loadParticipants(room) {
      const missingParticipantsIds = room.participantsIds
          .filter(participant => ! ChatParticipant.query().where('userId', participant).exists())

      const loadParticipantsRequests = missingParticipantsIds
          .map((loadParticipantsId) => messengerService.loadParticipant(loadParticipantsId))

      if (!loadParticipantsRequests.length) return false;

      axios.all(loadParticipantsRequests).then((responses) => {
        ChatParticipant.insert({ data: responses.map(response => response.data || {}) })
      });
    },
    scrollToBottom() {
      if (!this.$refs.roomContent) return false;
      this.$refs.roomContent.scrollTop = this.$refs.roomContent.scrollHeight;
    },
    messagesByUser(messages) {
      const byUser = [];

      messages.map((message) => {
        const participant = ChatParticipant.find(message.userId) || {};

        const previousUser = byUser[byUser.length - 1];
        const currentUser = this.isElterWithSelectedStudent ? this.parentSelectedStudent : this.currentUser;
        const messageUser = message.userId === currentUser.userId ? currentUser : participant;

        if (!byUser.length || previousUser.userId !== messageUser.userId) {
          if (
            !(
              this.currentUser.type !== "school-administrator" &&
              previousUser?.type === "school-administrator" &&
              messageUser.type === previousUser?.type
            )
          ) {
            byUser.push({ ...messageUser, messages: [] });
          }
        }

        byUser[byUser.length - 1].messages.push(message);
      });

      return byUser;
    },
    messagesByDate(messages) {
      const byDate = {};

      messages.map((message) => {
        const messageDate = format(
            parseISO(message.createdAt), "MMM dd",
            { locale: LocalizationManager.dateFnsLocale, }
        );

        if (!(messageDate in byDate)) byDate[messageDate] = [];

        byDate[messageDate].push(message);
      });

      return byDate;
    },
    loadMoreMessagesOnScroll(scrollHtmlElement) {
      if (!this.loadingRoom && scrollHtmlElement.scrollTop === 0) {
        const initialHeight = scrollHtmlElement.scrollHeight;

        if (this.isLoadMoreAvailable) {
          this.loadMoreMessages = true;

          messengerService
            .loadMessages({
              roomId: this.activeRoom.id,
              page: this.messagesPagination.currentPage + 1,
            })
            .then((resp) => {
              const pagination = resp.data.pagination || {
                currentPage: 1,
                lastPage: 1,
              };

              this.messagesPagination = {
                currentPage: pagination.currentPage,
                lastPage: pagination.lastPage,
              };

              ChatMessage.insert({ data: resp.data.data });
            })
            .finally(() => {
              this.loadMoreMessages = false;

              this.$nextTick(() => {
                scrollHtmlElement.scrollTop =
                  scrollHtmlElement.scrollHeight - initialHeight;
              });
            });
        }
      }
    },
    checkScroll() {
      const roomContent = this.$refs.roomContent;
      const scrollPosition = roomContent.scrollTop + roomContent.offsetHeight;
      const scrollStartPosition = roomContent.scrollHeight - 300;

      this.isScrollUp = scrollPosition <= scrollStartPosition;
    },
  },
};
</script>

<style lang="scss">
.room {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: auto;
  flex: 1;

  &__loader {
    top: calc(50% - 55px);
  }

  &__content {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  &__messages {
    width: 100%;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    margin: auto;
    padding: 10px 15px 0 15px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &__user {
      margin-top: 10px;
      .message:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    margin: 5px 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &_bar {
      position: sticky;
      bottom: 0;
      background-color: #fff;
      padding: 10px;
    }
  }

  .scroll__bottom {
    position: absolute;
    right: 20px;
    bottom: 80px;
  }
}

.messages {
  &__stack {
    width: 100%;
  }
}
</style>
