<template>
      <v-toolbar class="px-2" elevation="3">
        <v-btn
          class="mr-4"
          icon
          exact
          @click="goBack"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="title">{{ $t("label." + activeTab) }}</span>

        <slot name="ignore-spacer"></slot>

        <v-spacer></v-spacer>

        <slot></slot>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon large v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link v-for="(tab, index) in tabs" :key="index" :to="{ name: `students.student.`  + tab.value }">
              <v-list-item-title>{{ $t("label." + tab.value) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
</template>

<script>

import { mapGetters } from 'vuex'
import StudentModel from '@/store/models/StudentModel'

export default {
  name: "StudentTabToolBar",
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("school", ["school"]),
    item() {
      return StudentModel.find(this.$route.params.id) || {};
    },
    tabs() {
      return [
        { value: "education", isShow: true },
        { value: "finances", isShow: this.item.priceCategoryId, },
        { value: "documents", isShow: true },
        { value: "agenda", isShow: true },
        { value: "notes", isShow: true },
        { value: "invoices", isShow: this.school.isInvoicingEnabled && this.item.priceCategoryId, },
      ].filter((item) => item.isShow);
    }
  },
  methods: {
    goBack() {
      if (this.$route.params.licenseId) {
        this.$router.push({ name: "students.student.education" });
      } else {
        this.$router.push({ name: "students.student", params: { id: this.$route.params.id } });
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
