import { Model } from "@vuex-orm/core";
import ProductPriceModel from "./ProductPriceModel";

export default class ProductCategoryModel extends Model {
  static entity = "productCategories";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(""),
      studentsCount: this.number(0),
      studentsIds: this.attr([]),
      isDefault: this.boolean(0),
      isActive: this.boolean(true),
      isScheduled: this.boolean(true),
      licensesIds: this.attr([]),
      prices: this.hasMany(ProductPriceModel, "priceCategoryId"),
      licenseCompletionStatus: this.attr(null),
    };
  }
}
