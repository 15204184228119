<template>
  <div class="chat__search d-flex">
    <v-btn
      v-if="isSideBarMini"
      rounded
      small
      elevation="0"
      class="mx-auto pa-0 mt-2 mr-1"
      @click="openSearchModal"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-text-field
      v-if="!isSideBarMini"
      v-model="searchText"
      dense
      clearable
      full-width
      :label="searchLabel"
      outlined
      :hide-details="true"
      prepend-inner-icon="mdi-magnify"
      name="search-participants"
      autocomplete="off"
      @input="debouncedSearch"
      @click:prepend-inner="onTextFieldClick"
      @click:append="onTextFieldClick"
      @click="onTextFieldClick"
      @focus="onTextFieldClick"
      @blur="() => (isTextFieldInFocus = false)" />
  </div>
</template>

<script>
import ChatParticipant from "@/store/models/ChatParticipant";

import { mapGetters, mapState } from "vuex";
import { debounce } from "throttle-debounce";

export default {
  props: {
    isOpenedSearchList: {
      type: Boolean,
      required: true,
    },
  },
  name: "MessengerSidebarSearch",
  data: () => ({
    isTextFieldInFocus: false,
  }),
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapState("messenger", ["isSideBarMini"]),
    searchText: {
      set(value) {
        ChatParticipant.commit((state) => {
          state.searchText = value;
        });
      },
      get() {
        return this.$store.state.entities.chatParticipants.searchText;
      },
    },
    searchLabel() {
      if (this.currentUser.type === "school-administrator") {
        return `${this.$t("label.teachers")}, ${this.$t("label.students")}...`;
      }

      return this.$t("label.students");
    },
  },
  methods: {
    debouncedSearch: debounce(300, function () {
      this.$emit("search", this.searchText);
    }),
    onTextFieldClick() {
      if (!this.isTextFieldInFocus) this.$emit("openParticipantsList");

      this.isTextFieldInFocus = true;
    },
    openSearchModal() {
      this.$refs.searchParticipantModal.open();
    }
  },
};
</script>

<style scoped>
.chat__search {
  padding: 4px 8px 12px 8px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
</style>
