import { Model } from "@vuex-orm/core";
import EventModel from "./EventModel";
import { format, formatISO } from "date-fns";
import StudentModel from "@/store/models/StudentModel";

export default class TeacherModel extends Model {
  static entity = "teachers";

  static fields() {
    return {
      checked: this.boolean(false),
      loading: this.boolean(false),
      id: this.attr(null),
      userId: this.attr(null),
      avatar: this.attr(null),
      fullName: this.string(""),
      firstName: this.string(""),
      lastName: this.string(""),
      email: this.attr(null),
      phone: this.attr(null),
      street: this.attr(null),
      houseNr: this.attr(null),
      postcode: this.attr(null),
      city: this.attr(null),
      active: this.boolean(false),
      color: this.string(""),
      lessonDuration: this.attr(null),
      licensesIds: this.attr([]),
      cars: this.attr(null),
      events: this.hasMany(EventModel, "teacherId"),
      studentsIds: this.attr([]),
      vehiclesIds: this.attr([]),
      activeStudentsIds: this.attr([]),
      students: this.hasMany(StudentModel, "studentsIds"),
      studentCanCreatePractice: this.boolean(false),
      onlyPlaceholdersBooking: this.boolean(false),
      showStudentsAllTeachersEvents: this.boolean(false),
      birthDate: this.attr(null),
      createdAt: this.string(formatISO(new Date())),
      bookingLimitMax: this.number(""),
      availableWorkDayHours: this.attr(null),
      deletedAt: this.attr(null),
    };
  }

  get formatBirthDate() {
    return this.birthDate ? format(new Date(this.birthDate), "dd.MM.yyyy") : "";
  }

  get address() {
    let address = this.street;

    if (this.houseNr) address += " " + this.houseNr;
    if (this.postcode) address += ", " + this.postcode;
    if (this.city) address += " " + this.city;

    return address;
  }

  get isDeleted() {
    return this.deletedAt !== null;
  }
}
