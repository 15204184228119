import api from "@/utils/api-v2";

export default {
    loadTreeGroupTypes() {
        return new Promise((resolve, reject) => {
            api
                .get("/students-directory/tree-group-types")
                .then((resp) => {
                    resolve(resp);
                })
                .catch(reject);
        });
    },
    loadFiltersData() {
        return new Promise((resolve, reject) => {
            api
                .get("/students-directory/filters-data")
                .then((resp) => {
                    resolve(resp);
                })
                .catch(reject);
        });
    },
    load(params) {
        return new Promise((resolve, reject) => {
            api
                .get("/students-directory/students", { params })
                .then((resp) => {
                    resolve(resp);
                })
                .catch(reject);
        });
    },
    loadStudentFile(studentId) {
        return new Promise((resolve, reject) => {
            api
                .get(`/students-directory/students/${studentId}`)
                .then((resp) => {
                    resolve(resp);
                })
                .catch(reject);
        });
    }
}
